<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      :src="`/assets/dashboard/background.jpg`"
      gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)"
      max-height="100vh"
    >
      <v-responsive
        :style="styles"
        min-height="100vh"
        class="d-flex align-center"
      >
        <router-view />
      </v-responsive>
    </v-img>
  </v-main>
</template>

<script>
export default {
  name: "PagesCoreView",

  data: () => ({
    srcs: {
      "/pages/login": "background.jpg",
      "/pages/register": "background.jpg"
    }
  }),

  computed: {
    src() {
      return this.srcs[this.$route.path];
    },
    styles() {
      const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 50 : 0;
      const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 100 : 80;
      return {
        padding: `${paddingTop}px 0 ${paddingBottom}px 0`
      };
    }
  }
};
</script>
